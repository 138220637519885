import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from  './util/mapsHelper'

export const initHomeCaro = () => {
    const homeCaroImgs = [
        {src: "/static/images/downtownselfstorage01.jpg", alt: ""},
        {src: "/static/images/downtownselfstorage02.jpg", alt: ""},
        {src: "/static/images/downtownselfstorage03.jpg", alt: ""},
    ];

    AutomatitCarousel({
        element: document.querySelector('#home_caro'),
        images: homeCaroImgs,
        imagesAsBackgrounds: true,
        showDots: true,
        useChevrons: false,
    })
}

export const initHomeMap = () => { 
    const mapHelper = getMapsHelper();

    mapHelper.ready()
        .then(() => {
            const theMap = mapHelper.createMap({
                element: document.querySelector('#map_canvas'),
                locationElementSelector: '#map_canvas',
            })

            theMap.addListener('idle', () => {
                theMap.setZoom(15);
            })
        })
}

export const initFeaturedUnits = () => {
    const unitRows = document.querySelectorAll('#featured-units .unit-row');
    const windowListener = window.addEventListener('scroll', () => {
        unitRows.forEach(i => {
            const pos = i.getBoundingClientRect()
            if(pos.top >= 0 && pos.left >= 0 && pos.bottom <= window.innerHeight && pos.right <= window.innerWidth) {
                i.classList.add('animated', 'fadeInUp')
            }
        })
    })
}